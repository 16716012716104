#whatsapp-contact {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none; /* Initially hidden */
}

#whatsapp-contact a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25D366;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

#whatsapp-contact a:hover {
    background-color: #128C7E;
}

.whatsapp-icon {
    margin-right: 10px;
    font-size: 20px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    #whatsapp-contact {
        display: block;
    }
    
    #whatsapp-contact a {
        padding: 8px 16px; /* Adjust padding for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
    }
}

/* Notification styles */
.notification {
    position: fixed;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #28a745; /* Green background */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    transform: translate(-50%, -60%);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    to {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
}

.notification.show {
    visibility: visible;
    opacity: 1;
    animation: fadeIn 0.5s ease-out forwards, fadeOut 1s ease-out 2.5s forwards;
}
