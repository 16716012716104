@tailwind base;
@tailwind components;
@tailwind utilities;

.main-content {
    padding-top: calc(/* header height */ + 20px);
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fadeInUp {
    animation: fadeInUp 0.6s ease forwards;
  }

  